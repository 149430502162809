#progress-bar-section {
    background: linear-gradient(to right, whitesmoke 0%, white 15%, white 90%, whitesmoke 100%);
    color: black;

    h2 {
      color: black;
      font-size: 35px;
      line-height: 1.4em;
      text-align: center;
      max-width: 450px;
      margin: auto;
      padding: 50px 0 20px;
      text-shadow: 1px 1px gray;

      span {
        color: rgb(226, 44, 56);
        text-shadow: 1px 1px gray;
      }
    }

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 100px;
      flex-direction: row;
      position: relative;

      .left {
        width: 30%;
        text-align: right;
        padding: 50px 0;
        font-size: 22px;
        font-weight: 600;
      }

      .progress-bar {
        z-index: 1;
        width: 3px;
        height: 100%;
        background-color: #414141;
        position: absolute;
        left: 50%;
        margin-left: -1.5px;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: var(--scroll-height, 0%); /* Utilisation de la variable CSS */
          background-color: transparent; /* Couleur de progression */
          transition: height 0.3s ease-in-out; /* Transition pour une progression fluide */
          top: 0;
          left: 0;
        }
      }

      .dot {
        z-index: 2;
        font-size: 30px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .right {
        width: 30%;
        text-align: left;
        padding: 50px 0;
        font-size: 17px;

        span {
          font-weight: 900;
          background: black;
          border-radius: 10px;
          color: white;
          padding: 10px;
        }
      }
    }

    h3 {
      color: black;
      font-size: 30px;
      line-height: 1.4em;
      text-align: center;
      margin: auto;
      padding: 0 0 50px;
      text-shadow: 1px 1px gray;
    }
}

.icon-bounce {
  animation: bounce 1.5s ease;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(1.0);
  }
}

@media (max-width:804px){
  #progress-bar-section .step {
    flex-direction: column;
    gap: 0;
  }

  #progress-bar-section .step .progress-bar {
    left: 10%;
  }

  #progress-bar-section .step .dot {
    position: relative;
    right: 40%;
  }

  #progress-bar-section .step .left {
    padding: 50px 0 0;
    width: 50%;
    text-align: left;
  }

  #progress-bar-section .step .right {
    padding: 0 0 50px;
    width: 80%;
    text-align: left;
    padding-inline-start: 50px;
    font-size: 14px;
  }

  #progress-bar-section h2 {
    font-size: 30px;
  }

  #progress-bar-section h3 {
    font-size: 25px;
    margin-top: 20px;
  }
  #progress-bar-section p {
    margin-bottom: 0;
  }
}