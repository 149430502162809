@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');

#premium-2 {

/* NAVBAR SECTION */
#navbar {
    position: fixed;
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #000;
    border-bottom: 1px white solid;
    z-index: 10;
    .navbar-logo {
        padding-inline-start: 30px;
        z-index: 10;
      img {
        width: 300px;
      }
    }
  
    .navbar-menu {
      list-style: none;
      display: flex;
      gap: 20px;
      margin-bottom: 0;
      padding-inline-end: 30px;
  
      li {
        a {
          color: white;
          text-decoration: none;
          font-size: 13px;
          transition: color 0.3s ease;
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          font-weight: 600;
          &:hover {
            color: #FFD300;
          }
        }
      }
    }
    .navbar-hamburger {
        display: none;
        font-size: 24px;
        color: white;
        cursor: pointer;
    }
}
/* NAVBAR RESPONSIVE */
@media (max-width: 1024px) {
    #navbar {
        position: absolute;
        .navbar-logo {
            padding-inline-start: 0;
            img {
              width: 250px;
            }
        }
        .navbar-menu {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 250px;
            background-color: rgba(0, 0, 0, 0.9);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            transform: translateX(100%); /* Cacher le menu hors écran */
            transition: transform 0.3s ease;
        }
      
        .navbar-menu--open {
            transform: translateX(0);
            text-align: left;
        }
      
        .navbar-hamburger {
            display: flex; /* Afficher l'icône hamburger */
            z-index: 11;
            padding: 12px;
            background-color: white;
            border-radius: 5px;
            color: black;
            font-size: 20px;
        }
      
        .navbar-menu li a {
            font-size: 20px;
            color: white;
            
        }
    }

}
    




/* HEADER SECTION */
#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    height: 695px;
    overflow: hidden;
    padding: 70px;
    padding-top: 90px;
    padding-right: 0;
  
    .header__content {
      width: 50%;
      z-index: 5;
      .header__title {
        font-size: 60px;
        margin-bottom: 20px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: -1px;
        line-height: 60px;
        span {
            font-weight: 700;
        }
      }
      .header__paragraph {
        font-size: 18px;
        line-height: 1.6;
        color: #fff;
        margin-bottom: 30px;
        text-transform: uppercase; 
        font-weight: 600;    
        font-family: 'Raleway', sans-serif;
        max-width: 80%;
      }
      .header__cta {
        padding: 10px 29px;
        font-size: 12px;
        color: black;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        background-color: #FFD300;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.5s ease-in-out, background-color 0.3s ease;
    
        &:hover {
          background-color: #fff;
        }
      }
      .header__cta.visible {
        opacity: 1;
      }
    }
    .header__image {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        img {
          width: 100%;
          height: auto;
          object-fit: cover; // L'image remplit son conteneur
            object-position: top;
        }
    }
}
#header::before {
    content: '';
    position: absolute;
    top: 90px;
    left: 47%;
    width: 400px;
    height: 605px;
    background: linear-gradient(100deg, black 30%, #FFD300 30%, #FFD300 35%, white 35%, white 37%, transparent 39%, transparent 100%);
}
/* HEADER RESPONSIVE */
@media (max-width: 1024px) {
    #header {
        flex-direction: column-reverse;
        height: auto;
        padding: 0;
        .header__content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0px 0 50px;
            margin-top: 10px;
            background-color: black;
            .header__title {
                font-size: 50px;
            }
            .header__paragraph {
                max-width: 100%;
            }
        }
        .header__image {
            width: 100%;
            max-height: 500px;
            margin-top: 90px;
        }
    }
    #header::before {
        top: 0;
        left: 0%;
        max-width: 100%;
        width: 100%;
        height: 600px;
        background: linear-gradient(10deg, black 15%, #FFD300 15%, #FFD300 17%, white 17%, white 19%, transparent 19%, transparent 100%);
    }
}

/* SANTE SECTION */
#sante {
    padding: 100px 50px;
    text-align: center;
    background-color: #fff;
  
    .sante__header {
      margin-bottom: 40px;
  
      .sante__title {
        font-size: 36px;
        color: #333;
        font-weight: 400;
        letter-spacing: -1px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        span {
            color: #FFD300;
        }
      }
      .sante__subtitle{
        font-size: 16px;
        font-weight: 400;
        color: #00000075;
        letter-spacing: 2.5px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
      }
    }
  
    .sante__features {
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;
  
      .feature {
        width: 30%;
        text-align: center;
  
        .feature__icon {
          font-size: 60px;
          color: #000;
          margin: 20px 0;
        }
  
        .feature__title {
          font-size: 21px;
          color: #FFD300;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
  
        .feature__description {
          font-size: 14px;
          color: #777;
          line-height: 1.6;
        }
      }
    }
  
    .sante__cta {
      .cta-button {
        padding: 10px 30px;
        font-size: 16px;
        background-color: #000;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 30px;
        text-transform: uppercase;
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
  
        &:hover {
          background-color: #FFD300;
          color: #000;
        }
      }
    }
}
/* SANTE RESPONSIVE */
@media (max-width: 1024px) {
    #sante {
        padding: 50px 10px;
        .sante__header {
            margin-bottom: 20px;
            .sante__title {
                font-size: 33px;
            }
        }
        .sante__features {
            flex-direction: column;
            margin-bottom: 20px;
            .feature {
                width: 100%;
            }
        }

    }
}

  
/* DIVIDER SECTION */
#divider {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./premium-2-images/dx-ban.jpg');
    background-size: cover;
    background-blend-mode: multiply;
    background-position: center;
    padding: 60px 20px;
    color: white;
    text-align: center;
  
    .divider__content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;
      .divider__item {
        text-align: center;
    
        .divider__number {
          font-size: 91px;
          font-weight: 400;
          margin-bottom: 10px;
          color: #FFD300;
          font-family: 'Raleway', sans-serif;
        }
    
        .divider__description {
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 16px;
          letter-spacing: 1.3px;
        }
      }
    }
}
/* DIVIDER RESPONSIVE */
@media (max-width: 1024px) {
    #divider {
        .divider__content {
            flex-direction: column;
            .divider__item {
                .divider__number {
                    font-size: 70px;
                }
            }
        }
    }
}

/* SERVICES SECTION */
#services {
    padding: 60px 10px;
    background-color: #fff;
    text-align: center;
  
    .services__title {
      font-size: 36px;
      color: #333;
      font-weight: 400;
      letter-spacing: -1px;
      margin-bottom: 40px;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
  
      span {
        color: #FFD300;
      }
    }
  
    .services__subtitle {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4588235294);
      letter-spacing: 2.5px;
      margin-bottom: 0;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
    }
  
    .services__cards {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
    }
  
    .service-card {
      width: 350px;
      height: 530px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
      position: relative; /* To position the overlay */
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      color: #fff;
      gap: 20px;
  
      /* Overlay */
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        z-index: 1;
      }
  
      h3, p, button {
        z-index: 2;
      }
  
      &__title {
        font-size: 30px;
        font-weight: 800;
        margin-bottom: 10px;
        color: #fff;
        font-family: 'Raleway', sans-serif;
      }
  
      .service-card__price {
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.425));
      }
  
      &__description {
        font-size: 16px;
        color: #fff;
        font-family: 'Raleway', sans-serif;
      }
  
      .service-card__cta {
        padding: 10px 20px;
        background-color: #FFD300;
        border: none;
        border-radius: 30px;
        color: black;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        transition: background-color 0.3s ease;
        filter: drop-shadow(1px 1px 5px rgba(255, 255, 255, 0.425));
  
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
}
/* SERVICES RESPONSIVE */
@media (max-width: 1024px) {
    #services {
        padding: 60px 0 ;
        .services__title {
            font-size: 29px;
        }
        .services__cards {
            padding: 0 10px;
        }
    }
}


/* REVIEWS SECTION */
#reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #000;
    padding: 50px 20px;
  
    .image-container {
      width: 50%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  
    .carousel-container {
      width: 50%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .reviews-title{
        font-size: 36px;
        color: #fff;
        font-weight: 400;
        letter-spacing: -1px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        span {
            color: #FFD300;
        }
      }
      .reviews-subtitle {
        font-size: 16px;
        font-weight: 400;
        color: #ffffffd2;
        letter-spacing: 2.5px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        text-align: center;
      }
  
      .testimonial {
        text-align: center;
        color: #fff;
        height: 300px;

        .quote {
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: auto;
            color: #FFD300;
            font-size: 40px
        }
  
        p {
          font-size: 1.5rem;
          margin-bottom: 10px;
          font-style: italic;
          height: 150px;
          width: 80%;
          margin: 10px auto;
        }
        .author {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-bottom: 30px;
            h4 {
                font-size: 1.2rem;
                font-weight: bold;
                margin: 0;
            }
            .testimonial-img {
                  width: 50px; /* Taille de l'image de l'auteur */
                  height: 50px;
                  border-radius: 50%; /* Image circulaire */
                  object-fit: cover; /* Pour s'assurer que l'image garde un bon ratio */
            }
        }
        
      }
  
      .carousel-controls {
        display: flex;
        gap: 10px;
  
        button {
          padding: 10px 20px;
          background-color: #FFD300;
          color: #000;
          border: none;
          cursor: pointer;
          border-radius: 5px;
  
          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }
}
/* REVIEWS RESPONSIVE */
@media (max-width: 1024px) {
    #reviews {
        flex-direction: column;
        padding: 50px 0;
        .image-container {
            display: none;
        }
        .carousel-container {
            padding: 0;
            width: 100%;
            .testimonial {
                height: auto;
                .quote {
                    width: 100%;
                    padding: 0 20px;
                }
                p {
                    width: 100%;
                    height: 190px;
                }
            }
            .reviews-title {
                font-size: 31px;
            }
            .reviews-subtitle {
                font-size: 15px;
                letter-spacing: 1.5px;
            }
        }
    }
}
  

/* CONTACT SECTION */
#contact {
    padding: 60px 100px;
    background-color: #fff;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
  
    .contact__title{
        font-size: 36px;
        color: #333;
        font-weight: 400;
        letter-spacing: -1px;
        margin-bottom: 60px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
      span {
        color: #FFD300;
      }
    }
    .contact__subtitle {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4588235294);
        letter-spacing: 2.5px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        text-align: center;
    }
  
    .contact__content {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }
  
    .contact__info {
      width: 40%;
      
      h3 {
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 10px;
        color: #000;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        span {
            color: #FFD300;
            font-weight: 700;
        }
      }
  
      p {
        font-size: 16px;
        margin: 30px 0;
        width: fit-content;
        color: white;
        font-weight: 700;
        cursor: pointer;
        font-family: 'Raleway', sans-serif;
        a {
            text-decoration: none;
            color: white;
            padding: 15px 20px;
            background-color: #000;
        }
        a:hover {
            color: #000;
            background-color: #FFD300;
        }
      }
  
      .contact__socials {
        display: flex;
        gap: 15px;
        margin-bottom: 20px;
  
        a {
          font-size: 35px;
          color: #000;
          cursor: pointer;
          transition: all 0.3s ease;
  
          &:hover {
            color: #FFD300;
          }
        }
      }
    }
  
    .contact__form {
      width: 55%;
      h3 {
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 15px;
        color: #000;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        span {
            color: #FFD300;
            font-weight: 700;
        }
      }
  
      .form-group {
        margin-bottom: 20px;
        label {
            font-size: 16px;
            display: block;
            font-family: 'Raleway', sans-serif;
            span {
                color: #FFD300;
            }
          }

        input, textarea {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
          transition: border-color 0.3s ease;
  
          &:focus {
            border-color: #FFD300;
          }
        }
      }
  
      .contact__submit {
        background-color: #000;
        color: white;
        padding: 12px 30px;
        font-size: 18px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #FFD300;
          color: black;
        }
      }
    }
}
/* CONTACT RESPONSIVE */
@media (max-width: 1024px) {
    #contact {
        padding: 50px 10px;
        .contact__title {
            margin-bottom: 0;
            font-size: 33px;
        }
        .contact__content {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .contact__info {
                width: 100%;
                p {
                    width: auto;
                }
                .contact__socials {
                    justify-content: center;
                }
                h3 {
                    padding-top: 30px;
                }
            }
            .contact__form {
                width: 80%;
                max-width: 500px;
            }
        }
    }

}

/* SCROLL-TO-TOP */
.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    
    .scroll-button {
      /* Reprendre le style des boutons existants */
      padding: 10px 15px;
      border-radius: 10px;
      border: solid 1px white;
      background-color: #000;
      color: #FFD300;
      font-size: 16px;
      cursor: pointer;
      position: relative;
      transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
  
    }
    .scroll-button:hover {
        color: white;
    }
  }
  .scroll-to-top.show {
    opacity: 1;
    visibility: visible;
  }
  /* FOOTER SECTION */
  .footer {
    background-color: #000;
    background-position: center;
    background-size: cover;
    color: #fff; /* Couleur du texte */
    text-align: center; /* Centrer le texte */
    padding: 20px 0; /* Espacement intérieur */
    position: relative; /* Position relative pour le positionnement de l'élément */
    
    &__copyright {
      margin: 0; /* Pas de marge */
      font-size: 14px; /* Taille de police */
      font-family: 'Raleway', sans-serif;
    }
  
    &__legal-notice {
      color: #FFD300; /* Couleur du lien */
      text-decoration: none; /* Pas de soulignement */
      font-size: 14px; /* Taille de police */
      transition: color 0.3s; /* Transition pour l'effet au survol */
      font-family: 'Raleway', sans-serif;
  
      &:hover {
        color: #fff; /* Couleur au survol */
      }
    }
  }    
}


  
  
  