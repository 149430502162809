* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
  text-wrap: balance;
}

/* ACCUEIL */
#accueil .header-container {
  display: flex;
  text-align: center;
  position: relative;
  overflow: hidden;
}
#accueil .header-container {
  background: radial-gradient(ellipse at center, #131b25 0%, #000000 100%);
  overflow: hidden;
	/* background: linear-gradient(180deg, #000, #111, #000); */
	/* background-size: 400% 400%; */
	/* animation: gradient 10s ease infinite; */
	height: 100dvh;
}

@keyframes gradient {
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
}

#accueil .wrapper {
  width: 100%;
  position: absolute;
  top: 20%;
  color: white;
  padding: 10rem 0 0;
}

#accueil .wrapper .content {
  font: lighter 3rem "poppins", sans-serif;
  position: relative;
  padding-bottom: 10px;
  font-weight: 300;
}

#accueil .wrapper .content::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Hauteur de la "bordure" */
  background: linear-gradient(to right, transparent 30%, white, transparent 70%);
}

#accueil .wrapper .content spanpro {
  font-weight: 500;
}

#accueil .wrapper .content ol {
  list-style: none;
  --height: 3.8rem;
  height: var(--height);
  line-height: var(--height);
  overflow: hidden;
  padding-left: 0px;
}

#accueil .wrapper .content ol li {
  animation: slide-up 12s infinite;
}

@keyframes slide-up {
  0%, 10% {
      transform: translateY(0%);
  }
  15%, 25% {
      transform: translateY(-100%);
  }
  30%, 40% {
      transform: translateY(-200%);
  }
  45%, 55% {
      transform: translateY(-300%);
  }
  60%, 70% {
      transform: translateY(-400%);
  }
  75%, 85% {
      transform: translateY(-500%);
  }
}

#accueil .wrapper .content ol li span {
  font-weight: 900;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(rgb(226 44 56), rgba(170, 9, 36, 0.712));
  -webkit-background-clip: text;
  background-clip:to-lower-case($string: "");
  white-space: nowrap;
}

@media (max-width:804px){
  #accueil .wrapper .content {
    font-size: 1.8rem;
    padding-bottom: 0;
  }
  #accueil .wrapper .content::after {
    display: none;
  }
  #accueil .wrapper .content spanpro {
    display: none;
  }
}


/* TRIANGLE DIVIDER */
#triangle {
  position: relative;
  background: linear-gradient(to right, whitesmoke 0%, white 15%, white 90%, whitesmoke 100%);
  height: 10px;
}

#triangle::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 25px;
  border-color: #ffffff transparent transparent transparent;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
}


/* PRICING SECTION */
#tarifs {
  background-color: rgb(33, 31, 35);
  background-repeat: no-repeat;
  background: radial-gradient(circle at bottom, rgba(83, 69, 91, 1) 50%, rgba(32, 30, 34, 1) 70%);
  padding: 40px 20px;
}
#tarifs .details {
  width: 90%;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  padding: 40px 20px;
}
#tarifs .details h2 {
  font-size: 35px;
  margin-bottom: 10px;
  color: #fff;
}
#tarifs .details p {
  font-size: 15px;
  color: #fff;
}
#tarifs .grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 0;
  padding: 20px;
}
#tarifs .grid .box {
  width: 300px;
  border: 1px solid #eee;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.5);
}
#tarifs .grid .box .title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #555;
  padding: 0 0 15px;
  border-bottom: 1px solid;
}
#tarifs .grid .box .price {
  margin-bottom: 10px;
}
#tarifs .grid .box .price span {
  font-size: 11px;
  position: relative;
  bottom: 5px;
}
#tarifs .grid .box .price b {
  display: block;
  font-size: 40px;
  margin-bottom: -5px
}
#tarifs .grid .box .features > * {
  color: #555;
  font-size: 13px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
#tarifs .grid .box .button button {
  width: 100%;
  margin: 15px 0 0;
  padding: 10px;
  background: linear-gradient(rgb(226 44 56), rgba(140, 9, 31));
  color: #fff;
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: 600;
  cursor: pointer;
}
#tarifs .grid .premium {
  transform: scale(1.1);
  background: linear-gradient(rgb(226 44 56), rgb(140, 9, 31));
}
#tarifs .grid .premium .title {
  color: #eee;
}
#tarifs .grid .premium .price {
  color: #fff;
}
#tarifs .grid .premium .features > * {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
#tarifs .grid .premium .button button {
  background: #fff;
  color: #3a6073;
} 

@media (max-width:804px){
  #tarifs .grid {
    gap: 50px;
  }
  #tarifs .details {
    width: 100%;
  }
  #tarifs .details h2 {
    font-size: 30px;
  }
}

#tarifs .container {
  display: flex;
  align-items: center;
  .name {
    width: 90%;
    text-align: left;
    padding-inline-start: 5px;
  }
}



/* CAROUSSEL SECTION */
#temoignages .testimonial-carousel {
  width: 80%;
  margin: 0 auto;
}

#temoignages .testimonial {
  position: relative;
  width: 300px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

#temoignages .google-logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}

#temoignages .google-logo img {
  width: 100%;
  height: auto;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
}

#temoignages .client-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 10px;
}

#temoignages .client-photo img {
  width: 100%;
  height: auto;
}

#temoignages .client-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

#temoignages .date {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

#temoignages .stars {
  font-size: 20px;
  color: #f5b301;
  margin-bottom: 10px;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
}

#temoignages .comment {
  font-size: 16px;
  font-style: italic;
  color: #333;
  height: 100px;
  overflow: auto;
}



/* TÉMOIGNAGES */
#temoignages {
  background: linear-gradient(to right, whitesmoke 0%, white 15%, white 90%, whitesmoke 100%);
  padding: 80px 50px;
}
#temoignages .title {
  text-align: center;
}
#temoignages .title h2{
  font-size: 35px;
}

#temoignages .slick-list .slick-track {
  left: -10px;
}

#temoignages .slick-slide {
  padding: 20px 10px;
}

#temoignages .slick-slider .slick-arrow:before {
  font-size: 25px;
  color: rgba(128, 128, 128, 0.75);
  border-radius: 100%;
  height: 38px;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
}
#temoignages .slick-slider .slick-arrow:hover {
  opacity: 1;
}
#temoignages .slick-slider .slick-arrow:focus {
  opacity: 1;
}
#temoignages .slick-slider .slick-prev {
  left: -40px;
}
@media (max-width:804px){ 
  #temoignages {
    padding: 50px 20px;
  }
  #temoignages .title h2 {
    font-size: 30px;
  }
}



/* DIVIDER DESIGN */
#span-divider span {
  display: flex;
  height: 18px;
  width: 100%;
  background: linear-gradient(to bottom, #111,#fff);
}



/* DESIGN CAROUSEL */
#designs .designs-title {
  text-align: center;
  padding: 50px 0 30px;
  color: white;
  background: url(../public/images/wave.png);
  background-size: cover;
  background-position: bottom;
  width: 100%;
}

#premium .premium {
  background: radial-gradient(circle at center, rgba(83, 69, 91, 1) 40%, rgba(32, 30, 34, 1) 90%);
}

.carousel-section {
  padding: 20px;
  position: relative;
}

.carousel-title {
  text-align: left;
  font-size: 2rem;
  margin-inline-start: 30px;
  margin-block-start: 20px;
  width: fit-content;
}
.standard-title {
  color: black;
  position: relative;
  display: inline-block;
}

.standard-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px; /* Hauteur de la "bordure" */
  background: linear-gradient(to right, transparent, black 50%, transparent 100%);
}
#premium .premium-title {
  color: white;
  position: relative;
  display: inline-block;
}

#premium .premium-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px; /* Hauteur de la "bordure" */
  background: linear-gradient(to right, transparent, white 50%, transparent 100%);
}


.carousel-item {
  position: relative;
  text-align: center;
  height: 400px; /* Ajuste cette valeur selon la hauteur totale souhaitée du conteneur */
}

.carousel-item img {
  position: absolute;
  bottom: 0; /* Place l'image au bas de son conteneur */
  left: 50%;
  transform: translateX(-50%); /* Centre horizontalement l'image */
  transition: width 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%;
  opacity: 0.2;
  position: relative;
}

.test {
  height: 90%;
  overflow: hidden;
}
.parfait {
  overflow-y: scroll;
  height: -webkit-fill-available;
  width: 140%;
  position: relative;
  bottom: 20px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.8);
}

.featured img {
  max-width: 100%;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  padding: 0;
  box-shadow: 10px 4px 20px rgba(0, 0, 0, 0.5);
  position: relative;
}
.featured {
  right: 50px;
}

#premium .carousel-buttons, #standard .carousel-buttons {
  position: absolute;
  bottom: -130px; /* Les boutons apparaîtront en dessous de l'image */
  left: 75%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  z-index: 10;
}

#premium .carousel-buttons button, #standard .carousel-buttons button {
  padding: 10px 20px;
  background: linear-gradient(rgb(226 44 56), rgba(140, 9, 31));
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

#standard .slick-track, #premium .slick-track  {
  padding-bottom: 150px;
}

#standard .slick-slider .slick-arrow:before, #premium .slick-slider .slick-arrow:before {
  font-size: 30px;
  border-radius: 100%;
  height: 38px;
  opacity: 1;
}
#standard .slick-slider .slick-arrow:before {
  color: black;
}
#premium .slick-slider .slick-arrow:before {
  color: white;
}

#standard .slick-slider .slick-arrow:hover, #premium .slick-slider .slick-arrow:hover {
  opacity: 1;
}

#standard .slick-slider .slick-arrow:focus, #premium .slick-slider .slick-arrow:focus {
  opacity: 1;
}
#standard .slick-slider .slick-prev, #premium .slick-slider .slick-prev   {
  top: 45%;
  left: 150px;
}
#standard .slick-next, #premium .slick-next {
  top: 45%;
  right: 150px;
}

#standard .slick-prev , #standard .slick-next, #premium .slick-prev , #premium .slick-next {
  background: transparent;
  color: black;
  border-radius: 50%;
  z-index: 11;
}

.design-premium-id {
  font-size: 50px;
  font-weight: bold;
  opacity: 0.2;
  margin-bottom: 10px; /* Espace entre le numéro et l'image */
  color: transparent; /* Rendre l'intérieur transparent */
  -webkit-text-stroke: 1px white; /* Bordure blanche autour du texte */
}
.design-standard-id {
  font-size: 50px;
  font-weight: bold;
  opacity: 0.2;
  margin-bottom: 10px; /* Espace entre le numéro et l'image */
  color: transparent; /* Rendre l'intérieur transparent */
  -webkit-text-stroke: 1px black; /* Bordure blanche autour du texte */
}

.premium-num {
  width: 140%;
  position: relative;
  bottom: 15px;
  opacity: 1;
  transform: scale(1.2);
}
.standard-num {
  width: 140%;
  position: relative;
  bottom: 15px;
  opacity: 1;
  transform: scale(1.2);
}

.disabled-button {
  background-color: #d3d3d3; /* Gris pour les boutons désactivés */
  color: #808080;
  cursor: not-allowed;
  pointer-events: none; /* Empêche tout clic */
}

button[disabled] {
  opacity: 0.5; /* Rend le bouton partiellement transparent */
}

.reserved-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Couleur du texte */
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  z-index: 10; /* Assurez-vous qu'il est au-dessus de l'image */
  font-weight: 600;
  text-align: center;
}

.reserved-text::after {
  content: '';
  display: block;
  width: 100%; /* Largeur de la bordure */
  height: 1px; /* Hauteur de la bordure */
  background: linear-gradient(to right, transparent, white, transparent); /* Dégradé de blanc à transparent */
  margin-top: 5px; /* Espace entre le texte et la bordure */
}

.reserved-text.inactive {
  opacity: 0; /* Opacité réduite pour les éléments non actifs */
}

@media (max-width:804px){ 
  .designs-title h2 {
    font-size: 1.4rem;
  }
  .standard-num , .premium-num {
    width: 100%;
    left: 50px;
    bottom: 10px;
  }
  .parfait {
    width: 95%;
    left: 50px;
    box-shadow: 0 5px 10px black;
  }
  .carousel-buttons {
    left: 65%;
  }
  .carousel-buttons button {
    padding: 10px 5px;
  }
  .featured img {
    box-shadow: 0 0 black;
  }
  #standard .slick-next, #premium .slick-next {
    right: -7px;
    top: 50%;
  }
  #standard .slick-slider .slick-prev, #premium .slick-slider .slick-prev {
    left: -20px;
    opacity: 0;
  }
  .carousel-title {
    margin-inline-start: 0;
    margin-block-start: 0;
  }
}



/* TEAM SUPPORT */
 #team-support {
  padding: 50px;
  background-color: #f9f9f9;
 }
 
 #team-support .team-support-container {
  text-align: center;
  padding-bottom: 50px;
}
#team-support .team-support-paragraphe {
  font-size: 15px;
  max-width: 40%;
  margin: 0 auto 50px;
}

#team-support .team-support-title {
  font-size: 2.5rem;
  color: #333;
}

#team-support .team-support-sections {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

#team-support .team-support-item {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

#team-support .support-image {
  width: 80px;
  height: auto;
  margin-bottom: 15px;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
}

#team-support .team-support-container h3 {
  font-size: 1.5rem;
  color: #333;
}

#team-support .team-support-container p {
  font-size: 1rem;
  color: #555;
}

#team-support .reviews-summary {
  display: flex;
  gap: 30px;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
}

#team-support .review-item {
  text-align: center;
}

#team-support .stars {
  font-size: 24px;
  color: #f5b301;
}

#team-support .score {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

#team-support .review-item p {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

#team-support .logo-reviews {
  width: 75px;
  vertical-align: middle;
  margin-right: 5px;
}
#team-support .business-summary {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
#team-support .reviews-summary .business-summary {
  color: black;
  display: flex;
  align-items: center;
  font-size: 16px;
  max-width: 70%;
  margin: 0 auto 10px;
}

#team-support .cta-button {
  width: 100%;
  max-width: 250px;
  padding: 10px;
  background: linear-gradient(rgb(226 44 56), rgba(140, 9, 31));
  color: #fff;
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width:1024px){ 
  #team-support .reviews-summary {
    flex-direction: column;
    width: 100%;
  }
  #team-support .reviews-summary .business-summary {
    max-width: 85%;
    justify-content: center;
  }
}

@media (max-width:804px){ 
  #team-support .team-support-title {
    font-size: 1.8rem;
    text-wrap: pretty;
  }
  #team-support .team-support-paragraphe {
    max-width: 100%;
  }
}



/* SWIPE ANIMATION */
.swipe-container {
  display: none;
}
@media (max-width:804px){ 
  .swipe-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 80%;
    transform: translateY(250%);
    z-index: 10;
    filter: drop-shadow(2px 4px 6px black);
    background: linear-gradient(to right, transparent 5%, #0000001f, transparent 95%);
  }
}